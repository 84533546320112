<template>
  <div class="home">
    <h3 class="search-title">
      <font-awesome-icon icon="user-alt" /><span class="px-3">{{
        $t("home.search_by_name")
      }}</span>
    </h3>
    <div class="box">
      <div class="text">
        <div class="left">
          <form @submit.prevent="search">
            <div class="input-group">
              <label class="col-7 p-0">
                <input
                  maxlength="255"
                  value=""
                  name="n"
                  class="search-text form-control"
                  id="search-name"
                  type="text"
                  v-model="name"
                />
              </label>
              <label class="search-button">
                <button
                  name="search-submit"
                  class="btn btn-secondary input-group-append h-100 d-block"
                  id="search-name-btn"
                >
                  <font-awesome-icon icon="search" />
                  {{ $t("home.search") }}
                </button>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  mounted: function () {
    this.name = this.$store.state.search_condition.name
      ? this.$store.state.search_condition.name
      : "";
  },
  methods: {
    search: function () {
      const condition = {
        lang: this.$i18n.locale,
        m: "name",
        name: this.name,
        sort: "na",
        page: 1,
        size: 20,
      };
      this.$router.push({ path: "/search-result", query: condition });
      this.$store.commit("setCondition", condition);
    },
  },
};
</script>