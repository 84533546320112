import Vue from "vue";
import Router from "vue-router";
import Home from "@/pages/Home";
import SearchResult from "@/pages/SearchResult";
import ErrorPage from "@/pages/ErrorPage";

Vue.use(Router);

export default new Router({
    routes: [{
            path: "/",
            name: "Home",
            component: Home,
        },
        {
            path: "/search-result",
            name: "SearchResult",
            component: SearchResult
        },
        {
            path: '/api-error',
            name: 'ApiError',
            component: ErrorPage
        },
        {
            path: '*',
            name: 'NotFound',
            component: ErrorPage
        }
    ]
});