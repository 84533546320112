<template>
  <main>
    <div class="container-fluid pt-3" id="wrapper">
      <div id="header" class="d-none d-md-block">
        <div class="row">
          <div class="col-md-12 d-none d-md-block">
            <div class="title title-lang-tablet">
              <div>
                <p>{{ $t("common.home") }}</p>
              </div>
            </div>
            <div class="header-bar btn-group">
              <label
                class="btn radio-btn"
                v-bind:class="{ active: $i18n.locale === 'ja' }"
              >
                <input
                  type="radio"
                  value="ja"
                  class="radio hide-radiobtn"
                  @click="$router.push({ path: '/?lang=ja' })"
                />日本語
              </label>
              <label
                class="btn radio-btn"
                v-bind:class="{ active: $i18n.locale === 'en' }"
              >
                <input
                  type="radio"
                  value="en"
                  class="radio hide-radiobtn"
                  @click="$router.push({ path: '/?lang=en' })"
                />English
              </label>
            </div>
          </div>
        </div>
      </div>
      <nav class="col-12 d-block d-md-none menu">
        <Menu :isDisplayLangChange="isDisplayLangChange" />
      </nav>
      <div>
        <div id="contents" class="mt-md-0">
          <div id="column1" class="row p-0">
            <div class="col-md-12 col-lg-6">
              <div id="keyword">
                <ConditionKeyword />
              </div>
            </div>
            <div class="mt-3 mt-lg-0 col-md-12 col-lg-6">
              <div id="name">
                <ConditionName />
              </div>
            </div>
          </div>
          <div id="column2" class="row">
            <div class="align-self-start col-lg-6 col-xl-8">
              <div id="homeside">
                <ConditionOrganization :organizationList="organizationList" />
              </div>
            </div>
            <div class="mt-3 mt-lg-0 col-md-12 col-lg-6 col-xl-4">
              <div id="homemain">
                <ConditionField :fieldList="fieldList" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagereset">
      <a href="#" class="variable-font-size-top"
        >{{ $t("common.to_the_head_of_this_page") }}▲</a
      >
    </div>
  </main>
</template>

<script>
import api from "@/api";
import Menu from "@/components/Menu.vue";
import ConditionKeyword from "@/components/home/ConditionKeyword.vue";
import ConditionName from "@/components/home/ConditionName.vue";
import ConditionOrganization from "@/components/home/ConditionOrganization.vue";
import ConditionField from "@/components/home/ConditionField.vue";
import constant from "@/const/constant.js";

export default {
  components: {
    Menu,
    ConditionKeyword,
    ConditionName,
    ConditionOrganization,
    ConditionField,
  },
  data() {
    return {
      isDisplayLangChange: true,
      fieldList: [],
      organizationList: [],
    };
  },
  beforeCreate() {
    if (!this.$route.query.lang)
      this.$router.push({ query: { lang: this.$store.state.lang } });
  },
  created: function() {
    this.$store.commit("setCondition", {});
  },
  mounted: function () {
    this.initialize();
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      const language = this.$route.query.lang;
      this.setLocale(language);
      this.getFieldList();
      this.getOrganizationList();
    },
    setLocale(lan) {
      this.$emit("set-locale", lan);
    },
    getFieldList() {
      const self = this;
      api(constant.RESEARCH_AREAS_URL).then(function (value) {
        if (value) {
          self.fieldList = JSON.parse(JSON.stringify(value));
          self.fieldList.forEach((field) => {
            field.display = false;
          });
          self.$store.commit("setFieldList", self.fieldList);
        }
      });
    },
    getOrganizationList() {
      const self = this;
      api(constant.ORGANIZATIONS_URL).then(function (value) {
        if (value) {
          self.organizationList = [];
          const allOrganizationList = JSON.parse(JSON.stringify(value));

          allOrganizationList.forEach(o1 => {
            if(o1.members != 0){
              o1.items = o1.items.filter(o2 => o2.members != 0);
              o1.display = false;

              o1.items.forEach(o2 => {
                o2.items = o2.items.filter(o3 => o3.members != 0);
                o2.display = false;
              });
              
              self.organizationList.push(o1);
            }
          });
          self.$store.commit("setOrganizationList", self.organizationList);
        }
      });
    },
  },
};
</script>