import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faSearch,
    faUser,
    faList,
    faSquare,
    faPlusSquare,
    faMinusSquare,
    faBars,
    faBook,
    faInfoCircle,
    faUserAlt,
    faSchool,
    faFlask,
    faCaretLeft,
    faCaretRight
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faSearch,
    faUser,
    faList,
    faSquare,
    faPlusSquare,
    faMinusSquare,
    faBars,
    faBook,
    faInfoCircle,
    faUserAlt,
    faSchool,
    faFlask,
    faCaretLeft,
    faCaretRight
);

Vue.component("font-awesome-icon", FontAwesomeIcon);