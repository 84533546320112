<template>
  <div id="app">
    <Header />
    <router-view @set-locale="changeLocale" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      document.title = this.$i18n.tc("common.title");
      this.$store.commit("setLanguage", lang);
    },
  },
};
</script>

<style>
@import "./css/style.css";
</style>
