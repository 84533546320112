<template>
  <div class="dropdown btn float-right nav-item">
    <a
      class="btn btn-secondary dropdown-toggle"
      href="#"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <font-awesome-icon icon="bars" size="lg" />
      <div class="soran-btn-menu-font">{{ $t("common.menu") }}</div>
    </a>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <div class="border bg-light">
        <li v-if="isDisplayLangChange">
          <router-link
            v-if="$i18n.locale === 'ja'"
            :to="{ path: '/', query: { lang: 'en' } }"
            id="text-decoration-none"
            class="dropdown-item"
            >English Page</router-link
          >
          <router-link
            v-else
            :to="{ path: '/', query: { lang: 'ja' } }"
            id="text-decoration-none"
            class="dropdown-item"
            >Japanese Page</router-link
          >
        </li>
        <li>
          <a
            href="https://www.tohoku.ac.jp/"
            id="text-decoration-none"
            target="blank"
            class="dropdown-item"
            >{{ $t("menu.back_to_tohoku_univ") }}</a
          >
        </li>
        <li>
          <router-link
            v-if="$i18n.locale === 'ja'"
            to="/?lang=ja"
            id="text-decoration-none"
            class="dropdown-item"
            >{{ $t("common.home") }}</router-link
          >
          <router-link
            v-else
            to="/?lang=en"
            id="text-decoration-none"
            class="dropdown-item"
            >{{ $t("common.home") }}</router-link
          >
        </li>
      </div>
      <li id="menuClose" class="border-0">×</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    isDisplayLangChange: {
      type: Boolean,
    },
  },
  methods: {
    changeLocale(lan) {
      this.$emit("set-locale", lan);
    },
  },
};
</script>