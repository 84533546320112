import axios from 'axios'
import vm from '@/main';

const onSuccess = (response) => {
    return Promise.resolve(response.data)
}
const onError = () => {
    vm.$router.push({ name: "ApiError" }, () => {})
}
const api = (url, params = {}) => {
    return axios.get(url, params)
        .then(onSuccess)
        .catch(onError);
};

export default api;