<template>
  <div class="home">
    <h3 class="search-title">
      <font-awesome-icon icon="school" /><span class="px-3">{{
        $t("home.search_by_affiliation")
      }}</span>
    </h3>
    <div class="box">
      <div class="text">
        <div class="disp_block" id="affiliation_div_show">
          <p class="variable-font-size-top thk-color">
            <span v-if="$i18n.locale === 'ja'" class="link cursor-pointer"
              >※
              <span
                v-if="showAllDetailDisplayFlg"
                @click="switchAllDetailDisplay(false)"
                >{{ $t("home.org_non_display") }}</span
              >
              <span v-else @click="switchAllDetailDisplay(true)">{{
                $t("home.org_display")
              }}</span>
            </span>
            <span v-else>
              All 【
              <span
                @click="switchAllDetailDisplay(true)"
                class="link text-underline-hover thk-color cursor-pointer"
                >{{ $t("home.org_display") }}</span
              >
              ／
              <span
                @click="switchAllDetailDisplay(false)"
                class="link text-underline-hover thk-color cursor-pointer"
                >{{ $t("home.org_non_display") }}</span
              >
              】
            </span>
          </p>
        </div>
        <div class="d-md-flex">
          <div class="col-sm-12 col-md-6">
            <ul class="tree">
              <li
                v-for="organization in firstHalfOfList"
                :key="organization.code"
              >
                <span @click="switchDetailDisplay(organization.code)">
                  <span v-if="organization.items.length">
                    <font-awesome-icon
                      v-if="organization.display"
                      icon="minus-square"
                      class="
                        fa
                        variable-icon-size-top
                        fa-fw
                        list-mark
                        cursor-pointer
                      "
                    />
                    <font-awesome-icon
                      v-else
                      icon="plus-square"
                      class="
                        fa
                        variable-icon-size-top
                        fa-fw
                        list-mark
                        cursor-pointer
                      "
                    />
                  </span>
                  <font-awesome-icon
                    v-else
                    icon="square"
                    class="fa variable-icon-size-top fa-fw list-mark"
                  />
                </span>
                <span
                  @click="search(organization.code)"
                  class="variable-font-size-top link text-underline-hover thk-color"
                >
                  {{ organization[$i18n.locale] }}
                </span>
                <span v-if="organization.display">
                  <ul v-for="item in organization.items" :key="item.code">
                    <span v-if="item.items.length" @click="switchDetailDisplay(item.code)">
                      <font-awesome-icon
                        v-if="item.display"
                        icon="minus-square"
                        class="
                          fa
                          variable-icon-size-top
                          fa-fw
                          list-mark
                          cursor-pointer
                        "
                      />
                      <font-awesome-icon
                        v-else
                        icon="plus-square"
                        class="
                          fa
                          variable-icon-size-top
                          fa-fw
                          list-mark
                          cursor-pointer
                        "
                      />
                    </span>
                    <div v-else class="list-child-mark">
                      <font-awesome-icon
                        icon="square"
                        class="fa variable-icon-size-top fa-fw"
                      />
                    </div>
                    <span
                      @click="search(item.code)"
                      class="variable-font-size-top link text-underline-hover thk-color"
                    >
                      {{ item[$i18n.locale] }}
                    </span>
                    <!-- Lv3 -->
                    <span v-if="item.display">
                      <ul v-for="o3 in item.items" :key="o3.code">
                        <div class="list-child-mark">
                          <font-awesome-icon
                            icon="square"
                            class="fa variable-icon-size-top fa-fw"
                          />
                        </div>
                        <span
                          @click="search(o3.code)"
                          class="variable-font-size-top link text-underline-hover thk-color"
                        >
                          {{ o3[$i18n.locale] }}
                        </span>
                      </ul>
                    </span>
                    <!-- Lv3 -->
                  </ul>
                </span>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6">
            <ul class="tree">
              <li
                v-for="organization in latterHalfOfList"
                :key="organization.code"
              >
                <span @click="switchDetailDisplay(organization.code)">
                  <span v-if="organization.items.length">
                    <font-awesome-icon
                      v-if="organization.display"
                      icon="minus-square"
                      class="
                        fa
                        variable-icon-size-top
                        fa-fw
                        list-mark
                        cursor-pointer
                      "
                    />
                    <font-awesome-icon
                      v-else
                      icon="plus-square"
                      class="
                        fa
                        variable-icon-size-top
                        fa-fw
                        list-mark
                        cursor-pointer
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon
                      icon="square"
                      class="fa variable-icon-size-top fa-fw list-mark"
                    />
                  </span>
                </span>
                <span
                  @click="search(organization.code)"
                  class="variable-font-size-top link text-underline-hover thk-color"
                >
                  {{ organization[$i18n.locale] }}
                </span>
                <span v-if="organization.display">
                  <ul v-for="item in organization.items" :key="item.code">
                    <span v-if="item.items.length" @click="switchDetailDisplay(item.code)">
                      <font-awesome-icon
                        v-if="item.display"
                        icon="minus-square"
                        class="
                          fa
                          variable-icon-size-top
                          fa-fw
                          list-mark
                          cursor-pointer
                        "
                      />
                      <font-awesome-icon
                        v-else
                        icon="plus-square"
                        class="
                          fa
                          variable-icon-size-top
                          fa-fw
                          list-mark
                          cursor-pointer
                        "
                      />
                    </span>
                    <div v-else class="list-child-mark">
                      <font-awesome-icon
                        icon="square"
                        class="fa variable-icon-size-top fa-fw"
                      />
                    </div>
                    <span
                      @click="search(item.code)"
                      class="variable-font-size-top link text-underline-hover thk-color"
                    >
                      {{ item[$i18n.locale] }}
                    </span>
                    <!-- Lv3 -->
                    <span v-if="item.display">
                      <ul v-for="o3 in item.items" :key="o3.code">
                        <div class="list-child-mark">
                          <font-awesome-icon
                            icon="square"
                            class="fa variable-icon-size-top fa-fw"
                          />
                        </div>
                        <span
                          @click="search(o3.code)"
                          class="variable-font-size-top link text-underline-hover thk-color"
                        >
                          {{ o3[$i18n.locale] }}
                        </span>
                      </ul>
                    </span>
                    <!-- Lv3 -->
                  </ul>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllDetailDisplayFlg: false,
    };
  },
  props: {
    organizationList: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    firstHalfOfList() {
      return this.localizedOrganizationList.slice(0, this.localizedOrganizationList.length / 2);
    },
    latterHalfOfList() {
      return this.localizedOrganizationList.slice(this.localizedOrganizationList.length / 2);
    },
    localizedOrganizationList() {
      const lang = this.$i18n.locale;
      const orgs = this.organizationList.filter(o1 => o1[lang]);
      orgs.forEach(o1 => {
        o1.items = o1.items.filter(o2 => o2[lang]);
        o1.items.forEach(o2 => {
          o2.items = o2.items.filter(o3 => o3[lang]);
        });
      });
      return orgs;
    },
    flatOrganizationList() {
      const flatten = [];
      for (var i = 0; i < this.organizationList.length; i++) {
        const o1 = this.organizationList[i];
        flatten.push(o1);
        for (var j = 0; j < o1.items.length; j++) {
          const o2 = o1.items[j];
          flatten.push(o2);
          for (var k = 0; k < o2.items.length; k++) {
            const o3 = o2.items[k];
            flatten.push(o3);
          }
        }
      }
      return flatten;
    }
  },
  methods: {
    switchAllDetailDisplay(isDisplay) {
      this.organizationList.forEach(
        (organization) => (organization.display = isDisplay)
      );
      this.organizationList.splice();
      this.showAllDetailDisplayFlg = isDisplay;
    },
    switchDetailDisplay(code) {
      const targetorganization = this.flatOrganizationList.find(
        (organization) => organization.code === code
      );
      targetorganization.display = !targetorganization.display;
      this.organizationList.splice();
    },
    search: function (code) {
      const condition = {
        lang: this.$i18n.locale,
        m: "organization",
        sort: "j",
        o: code,
        page: 1,
        size: 20,
      };

      this.$store.commit("setCondition", condition);
      this.$router.push({ path: "/search-result", query: condition });
    },
  },
};
</script>