<template>
  <div>
    <p class="error-msg">{{ message }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    if (this.$route.path === "/api-error") {
      this.message = this.$i18n.tc("error.500");
    } else {
      this.message = this.$i18n.tc("error.404");
    }
  },
};
</script>