<template>
  <div class="home">
    <h3 class="search-title">
      <font-awesome-icon icon="flask" /><span class="px-3">{{
        $t("home.search_by_research_field")
      }}</span>
    </h3>
    <div class="box">
      <div class="text">
        <div class="disp_block" id="field_div_show">
          <p class="variable-font-size-top thk-color">
            <span v-if="$i18n.locale === 'ja'" class="link cursor-pointer"
              >※
              <span
                v-if="showAllDetailDisplayFlg"
                @click="switchAllDetailDisplay(false)"
                >{{ $t("home.field_non_display") }}</span
              >
              <span v-else @click="switchAllDetailDisplay(true)">{{
                $t("home.field_display")
              }}</span>
            </span>
            <span v-else>
              All 【
              <span
                @click="switchAllDetailDisplay(true)"
                class="link cursor-pointer"
                >{{ $t("home.field_display") }}</span
              >
              ／
              <span
                @click="switchAllDetailDisplay(false)"
                class="link cursor-pointer"
                >{{ $t("home.field_non_display") }}</span
              >
              】
            </span>
          </p>
        </div>
        <ul class="tree">
          <li v-for="field in fieldList" :key="field.code">
            <span @click="switchDetailDisplay(field.code)">
              <span v-if="field.items.length">
                <font-awesome-icon
                  v-if="field.display"
                  icon="minus-square"
                  class="
                    fa
                    variable-icon-size-top
                    fa-fw
                    list-mark
                    cursor-pointer
                  "
                />
                <font-awesome-icon
                  v-else
                  icon="plus-square"
                  class="
                    fa
                    variable-icon-size-top
                    fa-fw
                    list-mark
                    cursor-pointer
                  "
                />
              </span>
            </span>
            <span
              @click="search(field.code, null)"
              class="variable-font-size-top link text-underline-hover"
            >
              {{ field[$i18n.locale] }}
            </span>
            <span v-if="field.display">
              <ul v-for="item in field.items" :key="item.code">
                <li>
                  <div class="list-child-mark">
                    <font-awesome-icon
                      icon="square"
                      class="fa variable-icon-size-top fa-fw"
                    />
                  </div>
                  <span
                    @click="search(field.code, item.code)"
                    class="variable-font-size-top link text-underline-hover"
                  >
                    {{ item[$i18n.locale] }}
                  </span>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllDetailDisplayFlg: false,
    };
  },
  props: {
    fieldList: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    switchAllDetailDisplay(isDisplay) {
      this.fieldList.forEach((field) => (field.display = isDisplay));
      this.fieldList.splice();
      this.showAllDetailDisplayFlg = isDisplay;
    },
    switchDetailDisplay(code) {
      const targetField = this.fieldList.find((field) => field.code === code);
      targetField.display = !targetField.display;
      this.fieldList.splice();
    },
    search: function (a1, a2) {
      const condition = {
        lang: this.$i18n.locale,
        m: "field",
        sort: "j",
        a1: a1,
        page: 1,
        size: 20,
      };
      if (a2) {
        condition.a2 = a2;
      }

      this.$store.commit("setCondition", condition);
      this.$router.push({ path: "/search-result", query: condition });
    },
  },
};
</script>