import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    strict: false,
    state: {
        lang: "ja",
        search_condition: {},
        organizationList: [],
        fieldList: []
    },
    mutations: {
        setLanguage: function(state, lang) {
            state.lang = lang;
        },
        setCondition: function(state, condition) {
            state.search_condition = condition;
        },
        setOrganizationList: function(state, organizationList) {
            state.organizationList = organizationList;
        },
        setFieldList: function(state, fieldList) {
            state.fieldList = fieldList;
        }
    },
    plugins: [createPersistedState()]
})