<template>
  <div class="home">
    <h3 class="search-title">
      <font-awesome-icon icon="book" />&nbsp;<span class="px-2">{{
        $t("home.search_by_keyword")
      }}</span>
    </h3>
    <div class="box">
      <div class="text">
        <div class="left">
          <form @submit.prevent="search">
            <div class="input-group">
              <label class="col-7 p-0">
                <input
                  maxlength="255"
                  value=""
                  name="k"
                  class="search-text form-control"
                  id="search-keyword"
                  type="text"
                  v-model="keyword"
                />
              </label>
              <label class="search-button">
                <button
                  name="search-submit"
                  class="btn btn-secondary input-group-append h-100 d-block"
                  id="search-btn"
                >
                  <font-awesome-icon icon="search" />
                  {{ $t("home.search") }}
                </button>
              </label>
            </div>
          </form>
        </div>
        <div class="search-check">
          <div
            data-toggle="buttons"
            class="keyword-combination btn-group btn-group-toggle"
          >
            <label
              class="btn radio-btn"
              v-bind:class="{ active: operator === 'and' }"
            >
              <input
                type="radio"
                id="combination1"
                name="kc"
                value="and"
                v-model="operator"
                class="radio hide-radiobtn"
                @click="onChange"
              />{{ $t("home.and_search") }}
            </label>
            <label
              class="btn radio-btn"
              v-bind:class="{ active: operator === 'or' }"
            >
              <input
                type="radio"
                id="combination2"
                name="kc"
                value="or"
                v-model="operator"
                class="radio hide-radiobtn"
                @click="onChange"
              />{{ $t("home.or_search") }}
            </label>
          </div>
          <HowToUse />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HowToUse from "@/components/HowToUse.vue";

export default {
  data() {
    return {
      keyword: "",
      operator: "",
    };
  },
  components: {
    HowToUse,
  },
  mounted: function () {
    this.operator = this.$store.state.search_condition.operator
      ? this.$store.state.search_condition.operator
      : "and";
    this.keyword = this.$store.state.search_condition.k
      ? this.$store.state.search_condition.k
      : "";
  },
  methods: {
    search: function () {
      const condition = {
        lang: this.$i18n.locale,
        m: "keyword",
        k: this.keyword,
        operator: this.operator,
        sort: "na",
        page: 1,
        size: 20,
      };
      this.$router.push({ path: "/search-result", query: condition });
      this.$store.commit("setCondition", condition);
    },
    onChange(event) {
      const condition = this.$store.state.search_condition;
      condition.operator = event.target.value;
      this.$store.commit("setCondition", condition);
    },
  },
};
</script>