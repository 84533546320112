<template>
  <main>
    <loading :active.sync="isLoading"></loading>
    <div class="container-fluid pt-3" id="wrapper">
      <div id="header">
        <div class="row">
          <div class="d-none d-md-block">
            <div class="title">
              <div>
                <p>{{ $t("search_result.search_results") }}</p>
                <ul>
                  <li class="home__link">
                    <router-link v-if="$i18n.locale === 'ja'" to="/?lang=ja">{{
                      $t("common.home")
                    }}</router-link>
                    <router-link v-else to="/?=en">{{
                      $t("common.home")
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-12 d-block d-md-none ms-3 detail-icon-block-min-height"
          >
            <nav class="col-12 d-block d-md-none menu">
              <Menu :isDisplayLangChange="isDisplayLangChange" />
            </nav>
          </div>
        </div>
      </div>
      <div class="pt-md-4">
        <div id="contents">
          <div class="search">
            <div class="gray">
              <font-awesome-icon icon="search" class="title-icon" type/>
              <p>
                {{ $t("search_result.your_search") }}【{{ type }}】
                {{ searchContent }}
              </p>
            </div>
            <div class="search_form">
              <div class="inputaria">
                <p></p>
                <form @submit.prevent="research">
                  <div class="input-group">
                    <label class="search-keyword-area">
                      <input
                        maxlength="255"
                        class="search-text form-control form-control-sm"
                        v-model="keyword"
                        :placeholder="placeholderMessage"
                        name="k"
                        id="search-result-keyword"
                        type="text"
                      />
                    </label>
                    <span>
                      <label class="search-button">
                        <button
                          name="search-submit"
                          class="
                            btn btn-secondary
                            search-keyword-button
                            input-group-append
                          "
                          id="search-btn-result"
                          type="submit"
                        >
                          <font-awesome-icon icon="search" />
                          {{ $t("home.search") }}
                        </button>
                      </label>
                    </span>
                  </div>
                </form>
                <div
                  data-toggle="buttons"
                  class="keyword-combination btn-group btn-group-toggle"
                  id="searchRadio"
                >
                  <label
                    class="btn radio-btn"
                    v-bind:class="{ active: operator === 'and' }"
                  >
                    <input
                      type="radio"
                      id="combination1"
                      name="kc"
                      value="and"
                      v-model="operator"
                      class="radio hide-radiobtn"
                      @click="onChange"
                    />{{ $t("home.and_search") }}
                  </label>
                  <label
                    class="btn radio-btn"
                    v-bind:class="{ active: operator === 'or' }"
                  >
                    <input
                      type="radio"
                      id="combination2"
                      name="kc"
                      value="or"
                      v-model="operator"
                      class="radio hide-radiobtn"
                      @click="onChange"
                    />{{ $t("home.or_search") }}
                  </label>
                </div>
                <HowToUse />
              </div>
            </div>
          </div>
          <div class="search">
            <div class="gray search-result-count">
              <font-awesome-icon icon="list" class="title-icon" />
              <p>
                {{ $t("search_result.results") }}
                <span v-if="total >= 0">
                  <span v-if="$i18n.locale === 'ja'"
                    ><span v-if="total > 0"
                      >{{ total }}件中{{ startIndex }}-{{ endIndex }}件目</span
                    >
                    <span v-else>0件</span></span
                  >
                  <span v-else
                    ><span v-if="total > 0"
                      >{{ startIndex }}-{{ endIndex }} of about </span
                    >{{ total }}</span
                  >
                </span>
              </p>
            </div>
            <span v-if="total > 0">
              <div id="searchOrder">
                <label id="search-order-label1" class="me-2">
                  {{ $t("search_result.order") }}：
                  <select
                    v-model="sort"
                    name="o"
                    id="order"
                    @change="research()"
                  >
                    <option
                      v-for="(option, index) in sortOptions"
                      :value="option.value"
                      :key="index"
                    >
                      {{ option.display }}
                    </option>
                  </select>
                </label>
                <label id="search-order-label2">
                  {{ $t("search_result.number_of_results") }}：
                  <span v-if="$i18n.locale === 'en'" class="me-2">Display</span>
                  <select
                    v-model="perPage"
                    name="pp"
                    id="per-page"
                    @change="research()"
                  >
                    <option
                      v-for="(option, index) in countOptions"
                      :value="option.value"
                      :key="index"
                    >
                      {{ option.value }}
                    </option>
                  </select>
                  {{ $t("search_result.results_per_page") }}
                </label>
                <label />
              </div>
              <div class="row">
                <div
                  class="my-1 px-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"
                  v-for="(result, index) in searchResultList"
                  :key="index"
                >
                  <div class="card h-100">
                    <div class="card-body result">
                      <div class="psn-info">
                        <div class="photo">
                          <img :src="result.image" />
                        </div>
                        <div class="psn-name">
                          <div class="name-kna">
                            &nbsp;<span v-if="$i18n.locale === 'ja'">{{
                              result.name_kana
                            }}</span>
                          </div>
                          <div class="name-gng">
                            <a
                              :href="getDetailPageUrl(result.urn)"
                              class="link"
                            >
                              <span v-if="$i18n.locale === 'ja'">{{
                                result.name
                              }}</span>
                              <span v-else>{{ result.name_en }}</span>
                            </a>
                          </div>
                          <div style="display: flex; justify-content: space-between;">
                            <div class="name-title">{{ result.job_name }}</div>
                            <div v-if="result.org_code != $route.query.o && result.shokumu_org_code !== undefined && result.shokumu_org_code.includes($route.query.o)">
                              <span v-if="$i18n.locale === 'ja'" class="default-badge badge-color-kenmu">兼務</span>
                              <span v-else class="default-badge badge-color-kenmu">concurrently</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="psn-result">
                        <p class="h6 org-cd">
                          {{ result.org_name }}
                        </p>
                        <p class="kaknh-bnrui">
                          <span
                            v-for="(field, index) in result.research_field"
                            :key="index"
                          >
                            {{ field }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagenavi thk-color">
                <p>
                  <span
                    @click="paging(currentPage - 1)"
                    :class="[
                      currentPage == 1
                        ? 'invalid-link'
                        : 'text-underline-hover cursor-pointer',
                    ]"
                  >
                    <font-awesome-icon
                      icon="caret-left"
                      class="paging-prev"
                    />{{ $t("search_result.prev") }}
                  </span>
                  &nbsp;-&nbsp;
                  <span
                    @click="paging(currentPage + 1)"
                    :class="[
                      currentPage == Math.floor(total / perPage) + 1
                        ? 'invalid-link'
                        : 'text-underline-hover cursor-pointer',
                    ]"
                  >
                    {{ $t("search_result.next") }}
                    <font-awesome-icon icon="caret-right" class="paging-next" />
                  </span>
                </p>
                <ul class="navi">
                  <li
                    v-for="(i, index) in pageNumberList"
                    :key="index"
                    style="font-size: 15px"
                  >
                    <span v-if="i == '･･･'">{{ i }}</span
                    ><span
                      v-else
                      :class="[
                        i == currentPage
                          ? 'pagenow invalid-link'
                          : 'text-underline-hover cursor-pointer',
                      ]"
                      @click="paging(i)"
                      >{{ i }}</span
                    >
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="pagereset">
      <a href="#" class="variable-font-size-top"
        >{{ $t("common.to_the_head_of_this_page") }}▲</a
      >
    </div>
  </main>
</template>

<script>
import api from "@/api";
import Menu from "@/components/Menu.vue";
import HowToUse from "@/components/HowToUse.vue";
import constant from "@/const/constant.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Menu,
    HowToUse,
    Loading,
  },
  data() {
    return {
      isDisplayLangChange: false,
      keyword: "",
      operator: "",
      searchContent: "",
      searchResultList: [],
      total: -1,
      startIndex: 1,
      endIndex: 20,
      currentPage: 1,
      perPage: 20,
      sort: "",
      sortOptions: [
        { value: "na", display: this.$i18n.tc("search_result.sort_na") },
        { value: "nd", display: this.$i18n.tc("search_result.sort_nd") },
        { value: "o", display: this.$i18n.tc("search_result.sort_o") },
        { value: "j", display: this.$i18n.tc("search_result.sort_j") },
      ],
      countOptions: [
        { value: 20 },
        { value: 40 },
        { value: 60 },
        { value: 80 },
        { value: 100 },
      ],
      isLoading: false,
    };
  },
  async mounted() {
    this.initialize();
  },
  computed: {
    pageNumberList() {
      const last = Math.floor(this.total / this.perPage) + 1;
      const delta = 5;
      const left = this.currentPage - delta;
      const right = this.currentPage + (delta + 1);
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("･･･");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    type() {
      return this.$route.query.m
        ? this.$i18n.tc("search_result." + this.$route.query.m)
        : this.$i18n.tc(
            "search_result." + this.$store.state.search_condition.m
          );
    },
    placeholderMessage() {
      return this.$store.state.search_condition.k
        ? ""
        : this.$i18n.tc("search_result.filter_by_keywords");
    },
  },
  methods: {
    initialize: async function () {
      this.isLoading = true;
      const language = this.$route.query.lang
        ? this.$route.query.lang
        : this.$store.state.lang;
      this.sort = this.$route.query.sort ? this.$route.query.sort : "na";
      this.operator = this.$route.query.operator
        ? this.$route.query.operator
        : "and";
      this.currentPage = this.$route.query.page
        ? Number(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.size
        ? Number(this.$route.query.size)
        : 20;
      this.search();
      this.setLocale(language);
      this.setSearchContent();
    },
    setLocale(lan) {
      this.$emit("set-locale", lan);
    },
    onChange(event) {
      const condition = this.$store.state.search_condition;
      condition.operator = event.target.value;
      this.$store.commit("setCondition", condition);
    },
    search() {
      const self = this;
      const url = constant.SEARCH_API;
      const params = {
        params: {
          lang: this.$route.query.lang,
          k: this.$route.query.k,
          n: this.$route.query.name,
          o: this.$route.query.o,
          o1: this.$route.query.o1,
          o2: this.$route.query.o2,
          a1: this.$route.query.a1,
          a2: this.$route.query.a2,
          page: this.currentPage,
          size: this.perPage,
          sort: this.sort,
          operator: this.operator,
        },
      };
      api(url, params).then(function (value) {
        if (value) {
          const data = JSON.parse(JSON.stringify(value));
          self.total = data.total;
          self.startIndex = data.start;
          self.endIndex = data.end;
          self.searchResultList = data.hits;
        }
        self.isLoading = false;
      });
    },
    setSearchContent: async function () {
      this.keyword = this.$route.query.k;
      let list;
      switch (this.$route.query.m) {
        case "name":
          this.searchContent = this.$route.query.name;
          break;
        case "organization":
          list = this.$store.state.organizationList;
          if (list.length == 0)
            list = await this.getMasterList(constant.ORGANIZATIONS_URL);
          this.setSearchOlgName(
            list,
            this.$route.query.o
          );
          break;
        case "field":
          list = this.$store.state.fieldList;
          if (list.length == 0)
            list = await this.getMasterList(constant.RESEARCH_AREAS_URL);
          this.setSearchFieldName(
            list,
            this.$route.query.a1,
            this.$route.query.a2
          );
          break;
        default:
          break;
      }
    },
    async getMasterList(url) {
      api(url).then(function (value) {
        if (value) {
          return JSON.parse(JSON.stringify(value));
        }
      });
    },
    setSearchOlgName(list, code) {
      for (var i = 0; i < list.length; i++) {
        const o1 = list[i];
        if (o1.code == code) {
          this.searchContent = o1[this.$i18n.locale];
          break;
        }
        for (var j = 0; j < o1.items.length; j++) {
          const o2 = o1.items[j];
          if (o2.code == code) {
            this.searchContent = o1[this.$i18n.locale] + ' > ' + o2[this.$i18n.locale];
            break;
          }
          for (var k = 0; k < o2.items.length; k++) {
            const o3 = o2.items[k];
            if (o3.code == code) {
              this.searchContent = o1[this.$i18n.locale] + ' > ' + o2[this.$i18n.locale] + ' > ' + o3[this.$i18n.locale];
              break;
            }
          }
        }
      }
    },
    setSearchFieldName(list, c1, c2) {
      const target = list.find((v) => v.code == c1);
      this.searchContent = target[this.$i18n.locale];
      if (c2) {
        const item = target.items.find((v) => v.code == c2);
        this.searchContent = this.searchContent.concat(
          " ＞ ",
          item[this.$i18n.locale]
        );
      }
    },
    getDetailPageUrl(urn) {
      return `${constant.BASE_URL}/${this.$i18n.locale}/${urn}.html`;
    },
    paging(pageNum) {
      const condition = this.$store.state.search_condition;
      condition.page = pageNum;
      this.commitAndPushpage(condition);
    },
    research() {
      const condition = this.$store.state.search_condition;
      condition.size = this.perPage;
      condition.sort = this.sort;
      condition.k = this.keyword;
      condition.page = 1;
      this.commitAndPushpage(condition);
    },
    commitAndPushpage(condition) {
      this.$store.commit("setCondition", condition);
      this.$router.push({ path: "/search-result", query: condition });
      window.location.reload();
    },
  },
};
</script>