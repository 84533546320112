import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import "@/components/registerFontAwesome.js";

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.config.productionTip = false

const i18n = new VueI18n({
    locale: 'ja',
    messages: require('@/assets/messages.json')
})

const vm = new Vue({
    store,
    render: h => h(App),
    i18n,
    router
}).$mount('#app')

export default vm;